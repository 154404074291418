import { Outlet, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { Toaster, useAuth } from '@cg/module-frontend';
import * as React from 'react';
import { Footer, Breadcrumb } from '@cg/module-frontend/src/components';
import { ErrorBoundary } from '@cg/module-frontend/src/errors';
import { RBACConstants } from '@cg/common/src/constants';
import { boxConfig } from '@cg/module-frontend/src/config';
import { withAuthentication } from '@cg/module-frontend/src/core';
import { LeftNav } from './LeftNav';
import { TopNav } from './TopNav';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function DashboardLayout() {
  const { hasRole, fetchingSelf, login, self, isAuthenticated } = useAuth();
  useEffect(() => {
    if (fetchingSelf) {
      return;
    }

    if (!isAuthenticated || !self?.host) {
      login();
    }

    if (!hasRole(RBACConstants.Employee)) {
      window.location.href = boxConfig.baseUrls.commonGround;
    }
  }, [fetchingSelf]);

  return (
    <>
      <Toaster
        toastOptions={{
          className: 'border lg:min-w-96 min-w-80',
        }}
      />
      <main className="flex h-screen">
        <LeftNav />

        <div className="flex flex-col overflow-x-auto w-full">
          <TopNav />
          <div className="flex-grow w-full px-4 mt-5 border-b border-grey-darker">
            <Breadcrumb />
            <div className="-mt-5 min-w-0">
              <ErrorBoundary>
                <Outlet />
              </ErrorBoundary>
            </div>
          </div>
        </div>
      </main>
      <Footer />
      <ScrollToTop />
    </>
  );
}

export default withAuthentication(DashboardLayout);
