/* eslint-disable */


import { http, HttpResult } from '@cg/module-frontend/src/core/http';

import { CreateVenueRequest } from '../../../models/CreateVenueRequest';
import { Venue } from '../../../models/Venue';
import { VenueId } from '@cg/common/src/ids/VenueId';
import { Address } from '../../../models/Address';
import { Page } from '@cg/common/src/paging/Page';
import { UpdateAddressRequest } from '../../../models/UpdateAddressRequest';
import { UpdateVenueRequest } from '../../../models/UpdateVenueRequest';
import { CreateProfilePreSignedUrlRequest } from '../../../models/CreateProfilePreSignedUrlRequest';
import { PreSignedUrlResponse } from '../../../models/PreSignedUrlResponse';

export type CreateVenueArgs = {
    verifiedUser?: boolean,
    body: CreateVenueRequest,
}

/**
 * <p>API endpoints for Venues CRUD operations</p>
 * @summary Creates a new Venue
 * @param {CreateVenueArgs} args
 */
export const createVenue = (args: CreateVenueArgs): Promise<HttpResult<Venue>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/venues', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type DeleteVenueArgs = {
    verifiedUser?: boolean,
    ids: {
        venueId: VenueId,
    },
}

/**
 * <p>API endpoints for Venues CRUD operations</p>
 * @summary Deletes the Venue
 * @param {DeleteVenueArgs} args
 */
export const deleteVenue = (args: DeleteVenueArgs): Promise<HttpResult<void>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/venues/{venueId}', params);

    return http.delete(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type FetchAddressArgs = {
    verifiedUser?: boolean,
    ids: {
        venueId: VenueId,
    },
}

/**
 * @summary Endpoint for getting an Venue address.
 * @param {FetchAddressArgs} args
 */
export const fetchAddress = (args: FetchAddressArgs): Promise<HttpResult<Address>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/venues/{venueId}/address', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type FetchVenueArgs = {
    verifiedUser?: boolean,
    ids: {
        venueId: VenueId,
    },
}

/**
 * <p>API endpoints for Venues CRUD operations</p>
 * @summary Returns a Venue
 * @param {FetchVenueArgs} args
 */
export const fetchVenue = (args: FetchVenueArgs): Promise<HttpResult<Venue>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/venues/{venueId}', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type ListVenuesArgs = {
    verifiedUser?: boolean,
    filters?: {
        fake?: string,
        pageSize?: number,
        pageToken?: string,
    },
}

/**
 * <p>API endpoints for Venues CRUD operations</p>
 * @summary Returns a list of Venues
 * @param {ListVenuesArgs} args
 */
export const listVenues = (args: ListVenuesArgs): Promise<HttpResult<Page<Venue>>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/venues', params);

    return http.get(url, {
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type UpdateAddressArgs = {
    verifiedUser?: boolean,
    ids: {
        venueId: VenueId,
    },
    body: UpdateAddressRequest,
}

/**
 * @summary Endpoint for updating the Address of a Venue.
 * @param {UpdateAddressArgs} args
 */
export const updateAddress = (args: UpdateAddressArgs): Promise<HttpResult<Address>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/venues/{venueId}/address', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type UpdateVenueArgs = {
    verifiedUser?: boolean,
    ids: {
        venueId: VenueId,
    },
    body: UpdateVenueRequest,
}

/**
 * <p>API endpoints for Venues CRUD operations</p>
 * @summary Updates the Venue
 * @param {UpdateVenueArgs} args
 */
export const updateVenue = (args: UpdateVenueArgs): Promise<HttpResult<Venue>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/venues/{venueId}', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
export type UploadPhotoArgs = {
    verifiedUser?: boolean,
    ids: {
        venueId: VenueId,
    },
    body: CreateProfilePreSignedUrlRequest,
}

/**
 * <p>API endpoints for creating a Pre Signed URL for uploading files after to S3</p>
 * @summary Generates a Pre Signed URL for uploading a photo for the Venue
 * @param {UploadPhotoArgs} args
 */
export const uploadPhoto = (args: UploadPhotoArgs): Promise<HttpResult<PreSignedUrlResponse>> => {
    const params = {};
    // @ts-ignore
    if (args?.ids) {
      // @ts-ignore
      params.keys = { ...args.ids };
    }
    // @ts-ignore
    if (args?.filters) {
      // @ts-ignore
      params.queries = { ...args.filters };
    }

    const url = http.interpolatePath('/background/venues/{venueId}/photo', params);

    return http.post(url, {
        body: args.body,
      auth: {
        required: true,
        verifiedUser: args?.verifiedUser,
      },
    });
}
